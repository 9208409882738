<template>
    <div>
      <headerSection></headerSection>
      <div style="max-width: 100%;overflow-x: hidden;">
      <swiper
                  ref="mySwiper"
                  :options="swiperOptions"
                  style="padding-bottom:15px;padding-left:10px;padding-right:10px;padding-top:72px;background-color: #052530;"
                >
                  <swiper-slide v-for="(item,index) in banners" :key="index">
                    <img
                      style="margin: auto;width: 650px;border-radius: 15px;"
                      class="img-fluid -slick-item -item-1"
                      :alt="item.name"
                      width="1200"
                      height="590"
                      :src="item.image"
                  /></swiper-slide>
                  <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                </swiper>
      </div>
      <div id="main__content" class="x-homepage -sticky">
  
      <div class="x-index-content-main-container pb-4 -anon">
          <div class="x-category-total-game -v2">
              <nav class="nav-menu" id="navbarCategory">
                  <ul class="-menu-parent navbar-nav flex-row">
                      <li class="-list-parent nav-item px-lg-2 -category-favorite animated fadeInRight" data-animatable="fadeInRight" data-delay="100" v-if="user.key">
                          <div class="d-lg-block d-none">
                              <router-link to="/favorite" class="x-category-button -category-favorite -index-page  -category-button-v2 -hoverable ">
                                      <img alt=" cover image png" class="-img -default" src="../assets/images/menu/category-favorite.png?v=1">
                                      <img alt=" cover image png" class="-img -hover" src="../assets/images/menu/category-favorite-hover.png?v=1">
                              </router-link>
                          </div>
                          <div class="d-lg-none d-block w-100">
                              <router-link to="/favorite" class="x-category-button -category-favorite -index-page  -category-button-v2 -hoverable">
                                      <img alt="cover image png" class="-img-mobile" src="../assets/images/menu/favorite.png">
                                      <span class="-menu-text-main -text-btn-image">
                                          เกมส์ในดวงใจ
                                      </span>
                              </router-link>
                          </div>
                      </li>
                      <li class="-list-parent nav-item px-lg-2 -category-popular animated fadeInRight" data-animatable="fadeInRight" data-delay="100">
                          <div class="d-lg-block d-none">
                              <router-link to="/hotgames" class="x-category-button -category-popular -index-page  -category-button-v2 -hoverable ">
                                      <img alt=" cover image png" class="-img -default" src="../assets/images/menu/category-popular.png?v=1">
                                      <img alt=" cover image png" class="-img -hover" src="../assets/images/menu/category-popular-hover.png?v=1">
                              <!-- <span class="-menu-text-main -text-btn-image">
                                  ยอดนิยม
                                      </span> -->
                              </router-link>
                                                      </div>
  
                              <div class="d-lg-none d-block w-100">
                              <router-link to="/hotgames" class="x-category-button -category-popular -index-page  -category-button-v2 -hoverable">
                                      <img alt="cover image png" class="-img-mobile" src="../assets/images/menu/hot.png">
                                      <span class="-menu-text-main -text-btn-image">
                                          ยอดนิยม
                                      </span>
                              </router-link>
                          </div>
                       </li>
                                              <li class="-list-parent nav-item px-lg-2 -category-new-released animated fadeInRight" data-animatable="fadeInRight" data-delay="200">
  
                              <div class="d-lg-block d-none">
  
      <router-link to="/newgames" class="x-category-button -category-new-released -index-page  -category-button-v2 -hoverable">
              <img alt=" cover image png" class="-img -default" src="../assets/images/menu/category-new-released.png?v=1">
              <img alt=" cover image png" class="-img -hover" src="../assets/images/menu/category-new-released-hover.png?v=1">
              <!-- <span class="-menu-text-main -text-btn-image">
                  เกมใหม่
              </span> -->
      </router-link>
                              </div>
  
                              <div class="d-lg-none d-block w-100">
  
      <router-link to="/newgames" class="x-category-button -category-new-released -index-page  -category-button-v2 -hoverable">
              <img alt="cover image png" class="-img-mobile" src="../assets/images/menu/new-game.png">
              <span class="-menu-text-main -text-btn-image">
                  เกมใหม่
              </span>
      </router-link>
                              </div>
                          </li>
                                              <li class="-list-parent nav-item px-lg-2 -category-brand animated fadeInRight" data-animatable="fadeInRight" data-delay="300">
  
                              <div class="d-lg-block d-none">
  
      <router-link to="/provider" class="x-category-button -category-brand -index-page  -category-button-v2 -hoverable">
          <img alt=" cover image png" class="-img -default" src="../assets/images/menu/category-brand.png?v=1">
          <img alt=" cover image png" class="-img -hover" src="../assets/images/menu/category-brand-hover.png?v=1">
          <!-- <span class="-menu-text-main -text-btn-image">
              ค่ายเกมส์
          </span> -->
      </router-link>
                              </div>
  
                              <div class="d-lg-none d-block w-100">
  
      <router-link to="/provider" class="x-category-button -category-brand -index-page  -category-button-v2 -hoverable">
          <img alt="cover image png" class="-img-mobile" src="../assets/images/menu/slot.png">
          <span class="-menu-text-main -text-btn-image">
              สล๊อต
          </span>
      </router-link>
                              </div>
                          </li>
                                              <li class="-list-parent nav-item px-lg-2 -category-casino animated fadeInRight" data-animatable="fadeInRight" data-delay="400">
  
                              <div class="d-lg-block d-none">
  
      <router-link to="/casino" class="x-category-button -category-casino -index-page  -category-button-v2 -hoverable">
          <img alt=" cover image png" class="-img -default" src="../assets/images/menu/category-casino.png?v=1">
          <img alt=" cover image png" class="-img -hover" src="../assets/images/menu/category-casino-hover.png?v=1">
          <!-- <span class="-menu-text-main -text-btn-image">
              คาสิโนสด
          </span> -->
      </router-link>
                              </div>
  
                              <div class="d-lg-none d-block w-100">
  
  <router-link to="/casino" class="x-category-button -category-casino -index-page  -category-button-v2 -hoverable">
              <img alt="cover image png" class="-img-mobile" src="../assets/images/menu/casino.png">
              <span class="-menu-text-main -text-btn-image">
                  คาสิโนสด
              </span>
  </router-link>
                              </div>
                          </li>
                                              <li class="-list-parent nav-item px-lg-2 -category-skill-game animated fadeInRight" data-animatable="fadeInRight" data-delay="500">
  
                              <div class="d-lg-block d-none">
  
  <router-link to="/card" class="x-category-button -category-card -index-page  -category-button-v2 -hoverable">
  
              <img alt=" cover image png" class="-img -default" src="../assets/images/menu/category-skill-game.png?v=1">
  
              <img alt=" cover image png" class="-img -hover" src="../assets/images/menu/category-skill-game-hover.png?v=1">
  <!--
      <span class="-menu-text-main -text-btn-image">
          สกิลเกมส์
              </span> -->
  
      </router-link>
                              </div>
  
                              <div class="d-lg-none d-block w-100">
  
  <router-link to="/card" class="x-category-button -category-card -index-page  -category-button-v2 -hoverable">
  
              <img alt="cover image png" class="-img-mobile" src="../assets/images/menu/card.png">
  
      <span class="-menu-text-main -text-btn-image">
          เกมส์ไพ่
              </span>
  
   </router-link>
                              </div>
                          </li>
                                              <li class="-list-parent nav-item px-lg-2 -category-fishing-game animated fadeInRight" data-animatable="fadeInRight" data-delay="600">
  
                              <div class="d-lg-block d-none">
  
      <router-link to="/fish" class="x-category-button -category-fishing-game -index-page  -category-button-v2 -hoverable">
          <img alt=" cover image png" class="-img -default" src="../assets/images/menu/category-fishing-game.png?v=1">
          <img alt=" cover image png" class="-img -hover" src="../assets/images/menu/category-fishing-game-hover.png?v=1">
          <!-- <span class="-menu-text-main -text-btn-image">
              ยิงปลา
          </span> -->
      </router-link>
                              </div>
  
                              <div class="d-lg-none d-block w-100">
  
      <router-link to="/fish" class="x-category-button -category-fishing-game -index-page  -category-button-v2 -hoverable">
          <img alt="cover image png" class="-img-mobile" src="../assets/images/menu/fish.png">
          <span class="-menu-text-main -text-btn-image">
              ยิงปลา
          </span>
      </router-link>
                              </div>
                          </li>
                                              <li class="-list-parent nav-item px-lg-2 -category-sport animated fadeInRight" data-animatable="fadeInRight" data-delay="700">
  
                              <div class="d-lg-block d-none">
  
      <a href="/playsport" target="_blank" class="x-category-button -category-sport -index-page  -category-button-v2 -hoverable">
              <img alt=" cover image png" class="-img -default" src="../assets/images/menu/category-sport.png?v=1">
              <img alt=" cover image png" class="-img -hover" src="../assets/images/menu/category-sport-hover.png?v=1">
              <!-- <span class="-menu-text-main -text-btn-image">
                  สปอร์ต
              </span> -->
      </a>
                              </div>
  
                              <div class="d-lg-none d-block w-100">
      <a href="/playsport" target="_blank" class="x-category-button -category-sport -index-page  -category-button-v2 -hoverable">
              <img alt="cover image png" class="-img-mobile" src="../assets/images/menu/sport.png">
              <span class="-menu-text-main -text-btn-image">
                  สปอร์ต
              </span>
      </a>
                              </div>
                          </li>
                                      </ul>
              </nav>
          </div>
  
          <div class="-popular-games-list-container animated fadeInUp" data-animatable="fadeInUp" data-delay="150">
              <h2 class="-game-title h5 d-lg-none">Popular Games</h2>
              <ul class="navbar-nav">
                  <li class="nav-item d-none d-lg-inline-block">
                      <div class="x-game-list-heading-macro -best -big">
                          <div class="-inner-wrapper">
                              <picture>
                                  <source type="image/png" :data-srcset="(hotgames && hotgames[hotgames.length-1])?hotgames[hotgames.length-1].image.vertical:''" :srcset="(hotgames && hotgames[hotgames.length-1])?hotgames[hotgames.length-1].image.vertical:''">
                                  <img class="-cover-img lazyloaded" alt="wt-jili cover image png" :data-src="(hotgames && hotgames[hotgames.length-1])?hotgames[hotgames.length-1].image.vertical:''" :src="(hotgames && hotgames[hotgames.length-1])?hotgames[hotgames.length-1].image.vertical:''">
                              </picture>
                              <div class="-overlay">
                                  <span class="-length">{{(hotgames)?hotgames.length:0}} Games</span>
                                  <span class="-title mb-2">Popular Games</span>
                                  <router-link to="/hotgames" class="-seemore-btn">All games</router-link>
                              </div>
                          </div>
                      </div>
                  </li>
                  <li v-for="(item,index) in hotgames" :class="{'nav-item':true,'-first':(index === 0)?true:false}">
                      <div class="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable" data-status="-cannot-entry -untestable">
                          <div class="-inner-wrapper">
                              <div class="x-game-badge-component -hot -big animated fadeInUp" data-animatable="fadeInUp" data-delay="400">
                                  <span>Hot</span>
                              </div>
                              <div class="x-game-badge-image -big ">
                                  <img src="../assets/images/ez-slot-ic-master-key.png" class="-img" alt="badge game">
                              </div>
                              <picture>
                                  <template v-if="user.key">
                                      <router-link :to="{ name: 'PlayGame',query: {id: item.id,provider:item.provider,gameCategory:item.gameCategory}}" target="_blank">
                                          <source type="image/png" :data-srcset="item.image.vertical" :srcset="item.image.vertical">
                                          <img class="-cover-img img-fluid lazyloaded" alt="wt-rich88 cover image png" width="216" height="216" :data-src="item.image.vertical" :src="item.image.vertical">
                                      </router-link>
                                      <!-- <a :href="'/playgame/'+item.key" target="_blank" @click="playGame(item.id,item.provider,item.gameCategory)" class="-btn -btn-play js-account-approve-aware">
                                          <source type="image/png" :data-srcset="item.image.vertical" :srcset="item.image.vertical">
                                          <img class="-cover-img img-fluid lazyloaded" alt="wt-rich88 cover image png" width="216" height="216" :data-src="item.image.vertical" :src="item.image.vertical">
                                      </a> -->
                                  </template>
                                  <template v-else>
                                      <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" data-toggle="modal" data-target="#loginModal">
                                          <source type="image/png" :data-srcset="item.image.vertical" :srcset="item.image.vertical">
                                          <img class="-cover-img img-fluid lazyloaded" alt="wt-rich88 cover image png" width="216" height="216" :data-src="item.image.vertical" :src="item.image.vertical">
                                      </a>
                                  </template>
                              </picture>
                              <div class="-overlay">
                                  <div class="-overlay-inner">
                                      <div class="-wrapper-container">
                                          <template v-if="user.key">
                                              <router-link :to="{ name: 'PlayGame',query: {id: item.id,provider:item.provider,gameCategory:item.gameCategory}}" target="_blank" class="-btn -btn-play js-account-approve-aware">
                                                  <i class="fas fa-play"></i>
                                                  <span class="-text-btn">เข้าเล่น</span>
                                              </router-link>
                                          </template>
                                          <template v-else>
                                              <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" data-toggle="modal" data-target="#loginModal">
                                                  <i class="fas fa-play"></i>
                                                  <span class="-text-btn">เข้าเล่น</span>
                                              </a>
                                          </template>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="-title">{{item.gameName}}</div>
                      </div>
                  </li>
  
                  <li class="nav-item d-none d-lg-inline-block">
                      <div class="x-game-list-heading-macro -info -popular">
                          <div class="-inner-wrapper">
                              <picture>
                                  <source type="image/png" :srcset="(hotgames && hotgames[0])?hotgames[0].image.vertical:''">
                                  <img class="-cover-img" alt="wt-bbin-slot cover image png" :src="(hotgames && hotgames[0])?hotgames[0].image.vertical:''">
                              </picture>
                              <div class="-overlay">
                                  <h3 class="-title">รวมเกมแตกบ่อย สล็อตออนไลน์ยอดฮิต</h3>
                                  <ul class="-list">
                                      <li><span>อัตราจ่ายสูง</span></li>
                                      <li><span>โบนัสแตกง่าย</span></li>
                                      <li><span>ภาพและเสียงสนุก</span></li>
                                      <li><span>เล่นได้ทุกที่ 24 ชม.</span></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </li>
              </ul>
  
                      </div>
                      <!-- <div class="-popular-games-list-container animated fadeInUp" data-animatable="fadeInUp" data-delay="150">
              <h2 class="-game-title h5 d-lg-none">รีวิวถอนลูกค้า</h2>
              <ul class="navbar-nav">
                  <li v-for="(item,index) in splips" :class="{'nav-item':true,'-first':(index === 0)?true:false}" style="min-width: 150px;">
                      <div class="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable" data-status="-cannot-entry -untestable">
                          <div class="-inner-wrapper">
                              <div class="x-game-badge-image -big ">
                                  <img src="../assets/images/ez-slot-ic-master-key.png" class="-img" alt="badge game">
                              </div>
                              <picture>
                                <source type="image/png" :data-srcset="item.image" :srcset="item.image">
                                <img class="-cover-img img-fluid lazyloaded" width="316" height="216" :data-src="item.image" :src="item.image">
                              </picture>
                          </div>
                      </div>
                  </li>
              </ul>
            </div> -->
  
  <div class="-slot-games-list-container animated fadeInUp" data-animatable="fadeInUp" data-delay="150">
      <h2 class="-game-title h5 d-lg-none">Slot Games</h2>
      <ul class="navbar-nav">
          <li class="nav-item d-none d-lg-inline-block">
              <div class="x-game-list-heading-macro -slot -big">
                  <div class="-inner-wrapper">
                  <picture>
                      <source type="image/png" :data-srcset="(slotbrands && slotbrands[slotbrands.length-1])?slotbrands[slotbrands.length-1].image.vertical:''" :srcset="(slotbrands && slotbrands[slotbrands.length-1])?slotbrands[slotbrands.length-1].image.vertical:''">
                      <img class="-cover-img ls-is-cached lazyloaded" alt="sp cover image png" :data-src="(slotbrands && slotbrands[slotbrands.length-1])?slotbrands[slotbrands.length-1].image.vertical:''" :src="(slotbrands && slotbrands[slotbrands.length-1])?slotbrands[slotbrands.length-1].image.vertical:''">
                  </picture>
                  <div class="-overlay">
                      <span class="-length">{{this.getGamesLength(slotbrands)}} Games</span>
                      <span class="-title mb-2">Slot Games</span>
                      <router-link to="/provider" class="-seemore-btn">All games</router-link>
                      </div>
                  </div>
              </div>
          </li>
          <li v-for="(item,index) in slotbrands" :class="{'nav-item':true,'-first':(index === 0)?true:false}">
              <div class="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable" data-status="-cannot-entry -untestable">
                  <div class="-inner-wrapper">
                      <router-link :to="'/provider/'+item.provider" class="-btn -btn-play">
                          <picture>
                              <source type="image/png" :data-srcset="item.image.vertical" :srcset="item.image.vertical">
                              <img class="-cover-img img-fluid lazyloaded" alt="wt-pg-soft cover image png" width="249" height="361" :data-src="item.image.vertical" :src="item.image.vertical">
                          </picture>
                      </router-link>
                  <div class="-overlay">
                      <div class="-overlay-inner">
                          <div class="-wrapper-container">
                              <router-link :to="'/provider/'+item.provider" class="-btn -btn-play">
                                  <i class="fas fa-play"></i>
                                  <span class="-text-btn">เข้า Lobby</span>
                              </router-link>
                              <!-- <a href="/pgsoft" class="-btn -btn-play">
                                  <i class="fas fa-play"></i>
                                  <span class="-text-btn">เข้า Lobby</span>
                              </a> -->
                          </div>
                      </div>
                  </div>
                  </div>
                  <div class="-title">{{item.providerName}}</div>
              </div>
          </li>
          <li class="nav-item d-none d-lg-inline-block">
              <div class="x-game-list-heading-macro -info -slot">
                  <div class="-inner-wrapper">
                      <picture>
                          <source type="image/png" :srcset="(slotbrands && slotbrands[0])?slotbrands[0].image.vertical:''">
                          <img class="-cover-img" alt="sp cover image png" :src="(slotbrands && slotbrands[0])?slotbrands[0].image.vertical:''">
                      </picture>
                      <div class="-overlay">
                          <h3 class="-title">เล่นได้ทุกค่ายเกม ไม่ต้องโยกเงิน</h3>
                          <ul class="-list">
                              <li><span>มีให้เล่นทุกค่ายเกม</span></li>
                              <li><span>ให้บริการผ่านเว็บตรง</span></li>
                              <li><span>ระบบอัตโนมัติ</span></li>
                              <li><span>ปลอดภัย 100%</span></li>
                          </ul>
                      </div>
                  </div>
              </div>
          </li>
      </ul>
  
      </div>
  
          <div class="-new-games-list-container animated fadeInUp" data-animatable="fadeInUp" data-delay="100">
              <h2 class="-game-title h5 d-lg-none">New<br class="d-none d-lg-block"> Games</h2>
              <ul class="navbar-nav">
                  <li class="nav-item d-none d-lg-inline-block">
                      <div class="x-game-list-heading-macro -new -normal">
                          <div class="-inner-wrapper">
                          <picture>
                              <source type="image/png" :data-srcset="(newgames && newgames[0])?newgames[0].image.vertical:''" :srcset="(newgames && newgames[0])?newgames[0].image.vertical:''">
                              <img class="-cover-img lazyloaded" alt="wt-evo-play cover image png" :data-src="(newgames && newgames[0])?newgames[0].image.vertical:''" :src="(newgames && newgames[0])?newgames[0].image.vertical:''">
                          </picture>
                              <div class="-overlay">
                              <span class="-length">{{(newgames)?newgames.length:0}} Games</span>
                              <span class="-title mb-2">New<br class="d-none d-lg-block"> Games</span>
                              <router-link to="/newgames" class="-seemore-btn">All games</router-link>
                              </div>
                          </div>
                      </div>
                  </li>
                  <li v-for="(item,index) in newgames" :class="{'nav-item':true,'-first':(index === 0)?true:false}">
                      <div class="x-game-list-item-macro js-game-list-toggle -normal " data-status="">
                          <div class="-inner-wrapper">
                              <div class="x-game-badge-component -new -normal animated fadeInUp" data-animatable="fadeInUp" data-delay="400">
                                  <span>New</span>
                              </div>
                              <picture>
                                  <template v-if="user.key">
                                      <router-link :to="{ name: 'PlayGame',query: {id: item.id,provider:item.provider,gameCategory:item.gameCategory}}" target="_blank" class="-btn -btn-play js-account-approve-aware">
                                          <source type="image/png" :data-srcset="item.image.vertical" :srcset="item.image.vertical">
                                          <img class="-cover-img img-fluid lazyloaded" alt="wt-rich88 cover image png" width="216" height="216" :data-src="item.image.vertical" :src="item.image.vertical">
                                      </router-link>
                                  </template>
                                  <template v-else>
                                      <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" data-toggle="modal" data-target="#loginModal">
                                          <source type="image/png" :data-srcset="item.image.vertical" :srcset="item.image.vertical">
                                          <img class="-cover-img img-fluid lazyloaded" alt="wt-rich88 cover image png" width="216" height="216" :data-src="item.image.vertical" :src="item.image.vertical">
                                      </a>
                                  </template>
                              </picture>
                              <div class="-overlay">
                                  <div class="-overlay-inner">
                                      <div class="-wrapper-container">
                                          <template v-if="user.key">
                                              <router-link :to="{ name: 'PlayGame',query: {id: item.id,provider:item.provider,gameCategory:item.gameCategory}}" target="_blank" class="-btn -btn-play js-account-approve-aware">
                                                  <i class="fas fa-play"></i>
                                                  <span class="-text-btn">เข้าเล่น</span>
                                              </router-link>
                                          </template>
                                          <template v-else>
                                              <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" data-toggle="modal" data-target="#loginModal">
                                                  <i class="fas fa-play"></i>
                                                  <span class="-text-btn">เข้าเล่น</span>
                                              </a>
                                          </template>
                                          <!-- <a href="/lobby/test?channelCode=wt-rich88&amp;metadata%5Bgame_id%5D=CrushingPots" class="-btn -btn-demo" target="_blank" rel="nofollow noopener">
                                              ทดลองเล่น
                                          </a> -->
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="-title">{{item.gameName}}</div>
                      </div>
                  </li>
              </ul>
          </div>
  
          <div class="-games-splitter-zone-container -anon animated fadeInUp" data-animatable="fadeInUp" data-delay="250">
              <div class="-left-side">
  
  <div class="-live-casino-container animated fadeInUp" data-animatable="fadeInUp" data-delay="100">
      <h2 class="-game-title h5 d-lg-none">Live<br class="d-none d-lg-block"> Casino</h2>
  
      <ul class="navbar-nav">
          <li class="nav-item d-none d-lg-inline-block">
              <div class="x-game-list-heading-macro -live-casino -normal">
              <div class="-inner-wrapper">
                  <picture>
                      <source type="image/png" data-srcset="../assets/images/wt-n2live-square.png" srcset="../assets/images/wt-n2live-square.png">
                      <img class="-cover-img ls-is-cached lazyloaded" alt="wt-n2live cover image png" data-src="../assets/images/wt-n2live-square.png" src="../assets/images/wt-n2live-square.png">
                  </picture>
                  <div class="-overlay">
                      <span class="-length">{{this.getGamesLength(casinos)}} Games</span>
                      <span class="-title mb-2">Live<br class="d-none d-lg-block"> Casino</span>
                      <router-link to="/casino" class="-seemore-btn">All games</router-link>
                  </div>
              </div>
          </div>
          </li>
          <li v-for="(item,index) in casinos" :class="{'nav-item':true,'-first':(index === 0)?true:false}">
              <div class="x-game-list-item-macro js-game-list-toggle -normal " data-status="">
                  <div class="-inner-wrapper">
                      <router-link :to="'/casino/'+item.provider" class="-btn -btn-play">
                      <img :data-src="item.image.banner" :src="item.image.banner" class="-cover-img img-fluid ls-is-cached lazyloaded" alt="sa-gaming cover image png" width="216" height="216">
                      </router-link>
                      <div class="-overlay">
                          <div class="-overlay-inner">
                              <div class="-wrapper-container">
                                  <router-link :to="'/casino/'+item.provider" class="-btn -btn-play">
                                      <i class="fas fa-play"></i>
                                      <span class="-text-btn">เข้า Lobby</span>
                                  </router-link>
                                      <!-- <a href="/lobby/test?channelCode=sa-gaming" class="-btn -btn-demo" target="_blank" rel="nofollow noopener">
                                      ทดลองเล่น
                                  </a> -->
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="-title">{{item.providerName}}</div>
              </div>
          </li>
  
          <li class="nav-item d-lg-none">
              <div class="x-game-list-heading-macro -info -casino">
                  <div class="-inner-wrapper">
                      <picture>
                      <source type="image/webp" srcset="https://asset.cloudigame.co/build/admin/img/wt-n2live/ezs-wt-n2live-square.webp">
                      <source type="image/png" srcset="https://asset.cloudigame.co/build/admin/img/wt-n2live/ezs-wt-n2live-square.png">
                      <img class="-cover-img" alt="wt-n2live cover image png" src="https://asset.cloudigame.co/build/admin/img/wt-n2live/ezs-wt-n2live-square.png">
                      </picture>
                      <div class="-overlay">
                          <h3 class="-title">คาสิโนสดชั้นนำ ระดับโลก!</h3>
                          <ul class="-list">
                              <li><span>บาคาร่า</span></li>
                              <li><span>ซิกโบ</span></li>
                              <li><span>เสือมังกร</span></li>
                              <li><span>โป๊กเกอร์</span></li>
                              <li><span>รูเล็ต</span></li>
                              <li><span>แบล็คแจ็ค</span></li>
                              <li><span>ไฮโล</span></li>
                          </ul>
                      </div>
                  </div>
              </div>
          </li>
      </ul>
  
      </div>
              </div>
              <div class="-right-side">
                  <router-link to="/casino" class="-button-wrapper animated fadeInUp" data-animatable="fadeInUp" data-delay="100">
                      <span class="-text-title">คาสิโนสดชั้นนำ</span>
                      <span class="-text-sub-title">บาคาร่า | ซิกโบ | เสือมังกร | รูเล็ต</span>
                      <img data-src="../asets/images/ez-slot-guitar-card.png" class="-guitar-card lazyloaded" width="332" height="255" alt="Ezbet guitar card image" src="../assets/images/ez-slot-guitar-card.png">
                  </router-link>
              </div>
          </div>
  
          <div class="-promotion-section-wrapper -anon animated fadeInUp" data-animatable="fadeInUp" data-delay="350">
  
  <div class="x-big-win-v3-component">
  
      <div class="-row-wrapper">
  
              <!-- <img alt="Big win jackpot แตกง่ายที่นี่ที่เดียว PUG888" class="-girl-aqua-img" width="640" height="590" src="../assets/images/bigwin-last.png"> -->
  
          <!-- <div class="-col-video-wrapper mobile-video">
              <div class="-video-wrapper">
                      <img alt="Big win jackpot แตกง่ายที่นี่ที่เดียว PUG888" class="-video-frame img-fluid" width="960" height="1949" src="../assets/images/bigwin-v2-jackpot-frame.png?v=1">
                      <video loop="" playsinline="" autoplay="" muted="" class="-video">
                          <source src="../assets/images/bigwin-v2-jackpot.webm" type="video/webm">
                      </video>
              </div>
          </div> -->
  
              <!-- <img alt="Big win jackpot แตกง่ายที่นี่ที่เดียว PUG888" class="-girl-pink-img" width="640" src="../assets/images/bigwin-first.png"> -->
      </div>
  
          <img alt="Big win jackpot แตกง่ายที่นี่ที่เดียว PUG888" style="top:60px;" class="-text-img" width="865" height="230" src="../assets/images/bigwin-text.png?v=1">
      </div>
                      </div>
  
          <div class="-status-game-section-wrapper -anon">
              <div class="container">
                  <ul class="-status-game-container-wrapper ">
  
                          <li class="-status-list -wallet animated fadeInUp" style="" data-animatable="fadeInUp" data-delay="100">
                              <img class="-status-img" src="../assets/images/status-wallet.png" width="80" height="80" alt="PUG888 status list wallet">
                              <div class="-status-content">
                                  <h3 class="-status-title h5">Smart Wallet</h3>
                                  <span class="-status-detail">เล่นได้ทุกเกมส์<br>ไม่ต้องโยกเงิน</span>
                              </div>
                          </li>
  
                          <li class="-status-list -rank animated fadeInUp" style="" data-animatable="fadeInUp" data-delay="200">
                              <img class="-status-img" src="../assets/images/status-rank.png" width="80" height="80" alt="PUG888 status list rank">
                              <div class="-status-content">
                                  <h3 class="-status-title h5">อันดับ 1 ในไทย</h3>
                                  <span class="-status-detail">เทคโนโลยี และเกมส์<br>จากทั่วทุกมุมโลก</span>
                              </div>
                          </li>
  
                          <li class="-status-list -change animated fadeInUp" style="" data-animatable="fadeInUp" data-delay="300">
                              <img class="-status-img" src="../assets/images/status-change.png" width="80" height="80" alt="PUG888 status list change">
                              <div class="-status-content">
                                  <h3 class="-status-title h5">ฝาก - ถอน เร็วมาก</h3>
                                  <span class="-status-detail">ฝาก-ถอนเร็วมาก<br>ด้วยระบบ Bot AI</span>
                              </div>
                          </li>
  
                          <li class="-status-list -eveywhere animated fadeInUp" style="" data-animatable="fadeInUp" data-delay="400">
                              <img class="-status-img" src="../assets/images/status-eveywhere.png" width="80" height="80" alt="PUG888 status list eveywhere">
                              <div class="-status-content">
                                  <h3 class="-status-title h5">เล่นได้ทุกที่</h3>
                                  <span class="-status-detail">ระบบใช้ง่าย<br>รองรับทุกอุปกรณ์</span>
                              </div>
                          </li>
  
                          <li class="-status-list -support animated fadeInUp" style="" data-animatable="fadeInUp" data-delay="500">
                              <img class="-status-img" src="../assets/images/status-support.png" width="80" height="80" alt="PUG888 status list support">
                              <div class="-status-content">
                                  <h3 class="-status-title h5">บริการด้วยใจ</h3>
                                  <span class="-status-detail">ฝ่ายบริการลูกค้า<br>24 ชม ทุกวัน</span>
                              </div>
                          </li>
  
                          <li class="-status-list -promotion animated fadeInUp" style="" data-animatable="fadeInUp" data-delay="600">
                              <img class="-status-img" src="../assets/images/status-promotion.png" width="80" height="80" alt="PUG888 status list promotion">
                              <div class="-status-content">
                                  <h3 class="-status-title h5">โปรดีๆสำหรับคุณ</h3>
                                  <span class="-status-detail">กิจกรรมและโปรโมชั่น<br>หลากหลายแรงที่สุดในไทย</span>
                              </div>
                          </li>
                                      </ul>
              </div>
          </div>
      </div>
  
  
  <!-- 
  
  <div class="x-modal modal -alert-modal animated fadeInRight" id="alertModal" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".js-modal-content" data-ajax-modal-always-reload="true" data-animatable="fadeInRight" data-delay="700" data-dismiss-alert="true">
                              <div class="modal-dialog -modal-size  " role="document">
              <div class="modal-content -modal-content">
                                      <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                          <i class="fas fa-times"></i>
                      </button>
                                                                  <div class="modal-body -modal-body">
                          <div class="d-flex -alert-body">
          <div class="text-center mr-3 -alert-body-wrapper">
              <img src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic-alert-success.png" alt="SUCCESS" class="-img-alert js-ic-success img-fluid">
              <img src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic-alert-failed.png" alt="FAIL" class="-img-alert js-ic-fail img-fluid">
          </div>
          <div class="my-auto js-modal-content"></div>
      </div>
                  </div>
              </div>
          </div>
      </div> -->
  
  <!-- <div class="x-modal modal  " id="bookmarkModal" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".js-modal-content" data-ajax-modal-always-reload="true">
                              <div class="modal-dialog -modal-size          -no-fixed-button
      " role="document">
              <div class="modal-content -modal-content">
                                      <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                          <i class="fas fa-times"></i>
                      </button>
                                                      <div class="modal-header -modal-header">
                                                      <h3 class="x-title-modal d-inline-block m-auto">
                                  <span>Bookmark</span>                            </h3>
                                              </div>
                                                  <div class="modal-body -modal-body">
  
          <div class="x-bookmark-modal-container">
              <nav>
                  <div class="nav nav-tabs x-bookmark-tabs-header-wrapper" id="nav-tab" role="tablist">
                                                                      <a class="nav-link " id="nav-android-tab" data-toggle="tab" href="#nav-android" role="tab" aria-controls="nav-android" aria-selected="true">Android</a>
                                                                      <a class="nav-link active" id="nav-ios-tab" data-toggle="tab" href="#nav-ios" role="tab" aria-controls="nav-ios" aria-selected="true">iOS</a>
                                      </div>
              </nav>
  
              <div class="tab-content x-bookmark-tabs-content-wrapper" id="nav-tabContent">
  
                      <div class="tab-pane fade " id="nav-android" role="tabpanel" aria-labelledby="nav-android-tab">
                          <div class="-slide-wrapper -bookmark-slider-for-android slick-initialized slick-slider slick-dotted" data-slickable="{&quot;arrows&quot;:false,&quot;dots&quot;:true,&quot;slidesToShow&quot;:1,&quot;fade&quot;:true,&quot;infinite&quot;:true,&quot;autoplay&quot;:false,&quot;asNavFor&quot;:&quot;.-bookmark-slider-nav-android&quot;}">
                                                              <div class="slick-list draggable">
                                                                <div class="slick-track" style="opacity: 1; width: 15000px; transform: translate3d(-995px, 0px, 0px);"><div class="-slide-inner-wrapper -slick-item slick-slide slick-current slick-active" role="tabpanel" id="slick-slide00" aria-describedby="slick-slide-control00" style="width: 382px; position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;" data-slick-index="0" aria-hidden="false">
                                              <div class="-link-wrapper">
  
              <picture>
              <source type="image/webp" srcset="https://ezslot.bet/media/cache/strip/202206/block/8878cff0db2564e4c065ea6bf3715de1.webp">
              <source type="image/png" srcset="https://ezslot.bet/media/cache/strip/202206/block/8878cff0db2564e4c065ea6bf3715de1.png">
              <img class="img-fluid -slick-item -item-1" alt="banner 1" width="1200" height="590" src="https://ezslot.bet/media/cache/strip/202206/block/8878cff0db2564e4c065ea6bf3715de1.png">
          </picture>
  
                          </div>
                                      </div><div class="-slide-inner-wrapper -slick-item slick-slide" role="tabpanel" id="slick-slide01" aria-describedby="slick-slide-control01" style="width: 382px; position: relative; left: -382px; top: 0px; z-index: 998; opacity: 0; transition: opacity 500ms ease 0s;" data-slick-index="1" aria-hidden="true" tabindex="-1">
                                              <div class="-link-wrapper">
  
              <picture>
              <source type="image/webp" srcset="https://ezslot.bet/media/cache/strip/202206/block/44ef4f2a3c666b9144130285801557e4.webp">
              <source type="image/png" srcset="https://ezslot.bet/media/cache/strip/202206/block/44ef4f2a3c666b9144130285801557e4.png">
              <img class="img-fluid -slick-item -item-2" alt="banner 2" width="1200" height="590" src="https://ezslot.bet/media/cache/strip/202206/block/44ef4f2a3c666b9144130285801557e4.png">
          </picture>
  
                          </div>
                                      </div><div class="-slide-inner-wrapper -slick-item slick-slide" role="tabpanel" id="slick-slide02" aria-describedby="slick-slide-control02" style="width: 382px; position: relative; left: -764px; top: 0px; z-index: 998; opacity: 0; transition: opacity 500ms ease 0s;" data-slick-index="2" aria-hidden="true" tabindex="-1">
                                              <div class="-link-wrapper">
  
              <picture>
              <source type="image/webp" srcset="https://ezslot.bet/media/cache/strip/202206/block/3d14d71e19ceb656454d5c07e0da50e6.webp">
              <source type="image/png" srcset="https://ezslot.bet/media/cache/strip/202206/block/3d14d71e19ceb656454d5c07e0da50e6.png">
              <img class="img-fluid -slick-item -item-3" alt="banner 3" width="1200" height="590" src="https://ezslot.bet/media/cache/strip/202206/block/3d14d71e19ceb656454d5c07e0da50e6.png">
          </picture>
  
                          </div>
                                      </div></div></div>
  
                                                      <ul class="slick-dots" style="" role="tablist"><li class="slick-active" role="presentation"><button type="button" role="tab" id="slick-slide-control10" aria-controls="slick-slide10" aria-label="1 of 3" tabindex="0" aria-selected="true">1</button></li><li role="presentation"><button type="button" role="tab" id="slick-slide-control11" aria-controls="slick-slide11" aria-label="2 of 3" tabindex="-1">2</button></li><li role="presentation"><button type="button" role="tab" id="slick-slide-control12" aria-controls="slick-slide12" aria-label="3 of 3" tabindex="-1">3</button></li></ul></div>
  
                          <div class="-slide-wrapper -bookmark-slider-nav-android slick-initialized slick-slider" data-slickable="{&quot;arrows&quot;:false,&quot;dots&quot;:false,&quot;slidesToShow&quot;:1,&quot;fade&quot;:true,&quot;infinite&quot;:true,&quot;autoplay&quot;:false,&quot;asNavFor&quot;:&quot;.-bookmark-slider-for-android&quot;}">
                                                              <div class="slick-list draggable"><div class="slick-track" style="opacity: 1; width: 0px;"><div class="-slide-inner-wrapper px-3 pt-3 slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;">
                                      <div class="-content-wrapper -center">
                                                                                      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-slide-number-1.png" class="-icon lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="45" height="70">
                                                                                  <div class="-description">เข้า Google Chrome แล้ว Search <br> PUG888 เข้าสู่หน้าเว็บ</div>
                                      </div>
                                  </div><div class="-slide-inner-wrapper px-3 pt-3 slick-slide" data-slick-index="1" aria-hidden="true" tabindex="-1" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                      <div class="-content-wrapper -center">
                                                                                      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-slide-number-2.png" class="-icon lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="45" height="70">
                                                                                  <div class="-description">เลือก “ติดตั้ง”</div>
                                      </div>
                                  </div><div class="-slide-inner-wrapper px-3 pt-3 slick-slide" data-slick-index="2" aria-hidden="true" tabindex="-1" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                      <div class="-content-wrapper -center">
                                                                                      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-slide-number-3.png" class="-icon lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="45" height="70">
                                                                                  <div class="-description">ตรวจสอบหน้า <br> โฮมสกรีนว่ามีไอคอนขึ้นแล้ว</div>
                                      </div>
                                  </div></div></div>
  
                                                      </div>
                      </div>
  
                      <div class="tab-pane fade show active" id="nav-ios" role="tabpanel" aria-labelledby="nav-ios-tab">
                          <div class="-slide-wrapper -bookmark-slider-for-ios slick-initialized slick-slider slick-dotted" data-slickable="{&quot;arrows&quot;:false,&quot;dots&quot;:true,&quot;slidesToShow&quot;:1,&quot;fade&quot;:true,&quot;infinite&quot;:true,&quot;autoplay&quot;:false,&quot;asNavFor&quot;:&quot;.-bookmark-slider-nav-ios&quot;}">
                                                              <div class="slick-list draggable"><div class="slick-track" style="opacity: 1; width: 0px;"><div class="-slide-inner-wrapper slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" role="tabpanel" id="slick-slide30" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;">
                                      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-ios-1.png" class="-img lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="250" height="538">
                                  </div><div class="-slide-inner-wrapper slick-slide" data-slick-index="1" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide31" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-ios-2.png" class="-img lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="250" height="538">
                                  </div><div class="-slide-inner-wrapper slick-slide" data-slick-index="2" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide32" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-ios-3.png" class="-img lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="250" height="538">
                                  </div><div class="-slide-inner-wrapper slick-slide" data-slick-index="3" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide33" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-ios-4.png" class="-img lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="250" height="538">
                                  </div></div></div>
  
                                                      <ul class="slick-dots" style="" role="tablist"><li class="slick-active" role="presentation"><button type="button" role="tab" id="slick-slide-control30" aria-controls="slick-slide30" aria-label="1 of 4" tabindex="0" aria-selected="true">1</button></li><li role="presentation"><button type="button" role="tab" id="slick-slide-control31" aria-controls="slick-slide31" aria-label="2 of 4" tabindex="-1">2</button></li><li role="presentation"><button type="button" role="tab" id="slick-slide-control32" aria-controls="slick-slide32" aria-label="3 of 4" tabindex="-1">3</button></li><li role="presentation"><button type="button" role="tab" id="slick-slide-control33" aria-controls="slick-slide33" aria-label="4 of 4" tabindex="-1">4</button></li></ul></div>
  
                          <div class="-slide-wrapper -bookmark-slider-nav-ios slick-initialized slick-slider" data-slickable="{&quot;arrows&quot;:false,&quot;dots&quot;:false,&quot;slidesToShow&quot;:1,&quot;fade&quot;:true,&quot;infinite&quot;:true,&quot;autoplay&quot;:false,&quot;asNavFor&quot;:&quot;.-bookmark-slider-for-ios&quot;}">
                                                              <div class="slick-list draggable"><div class="slick-track" style="opacity: 1; width: 0px;"><div class="-slide-inner-wrapper px-3 pt-3 slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;">
                                      <div class="-content-wrapper -center">
                                                                                      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-slide-number-1.png" class="-icon lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="45" height="70">
                                                                                  <div class="-description">เข้า Safari แล้ว Search PUG888 <br> เข้าสู่หน้าเว็บ กดที่ <i class="fa-solid fa-arrow-up-from-square"></i></div>
                                      </div>
                                  </div><div class="-slide-inner-wrapper px-3 pt-3 slick-slide" data-slick-index="1" aria-hidden="true" tabindex="-1" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                      <div class="-content-wrapper -center">
                                                                                      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-slide-number-2.png" class="-icon lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="45" height="70">
                                                                                  <div class="-description">เลือก “เพิ่มลงใปยังหน้าจอโฮม”</div>
                                      </div>
                                  </div><div class="-slide-inner-wrapper px-3 pt-3 slick-slide" data-slick-index="2" aria-hidden="true" tabindex="-1" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                      <div class="-content-wrapper -center">
                                                                                      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-slide-number-3.png" class="-icon lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="45" height="70">
                                                                                  <div class="-description">กด “เพิ่ม”ทางลัดเข้าสู่เกมส์ <br> ลงในหน้าจอโฮม</div>
                                      </div>
                                  </div><div class="-slide-inner-wrapper px-3 pt-3 slick-slide" data-slick-index="3" aria-hidden="true" tabindex="-1" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                      <div class="-content-wrapper -center">
                                                                                      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-slide-number-4.png" class="-icon lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="45" height="70">
                                                                                  <div class="-description">ตรวจสอบหน้า <br> โฮมสกรีนว่ามีไอคอนขึ้นแล้ว</div>
                                      </div>
                                  </div></div></div>
  
                                                      </div>
                      </div>
                              </div>
          </div>
  
                      </div>
              </div>
          </div>
      </div> -->
      <div
          class="x-modal modal  "
          id="ads-no-show-again"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-loading-container=".js-modal-content"
          data-ajax-modal-always-reload="true"
          data-modal-one-time="Announcement Banner 1"
          data-check-target="#ads-no-show-again"
        >
          <div class="modal-dialog -modal-size " role="document">
            <div class="modal-content -modal-content">
              <button
                type="button"
                class="close f-1 "
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
              <div class="modal-header -modal-header">
                <h3 class="x-title-modal d-inline-block m-auto">
                  <span>📢 ประกาศสำคัญ</span>
                </h3>
              </div>
              <div class="modal-body -modal-body">
                <div class="text-center" v-if="anoucement.image">
                  <img
                    :src="anoucement.image"
                    alt="Announcement Shiba888"
                    class="img-fluid"
                    width="350px"
                  />
                </div>
                <br>
                <div v-html="nl2br(anoucement.detail)" style="color:#000;font-size:15px;font-family: 'Kanit', sans-serif;"></div>
                <div class="x-checkbox-primary">
                  <div class="text-primary text-center mt-4">
                    <input
                      type="checkbox" v-model="receiveAnounceMent"
                    />&nbsp;&nbsp;&nbsp;&nbsp;
                    <label style="color:#000;font-size:14px;font-family: 'Kanit', sans-serif;">
                      ไม่ต้องแสดงข้อความนี้อีก
                    </label>
                  </div>
                </div>
  
                <div class="my-3">
                  <a
                    @click="submitAnoucement()"
                    data-dismiss="modal"
                    class="text-black btn btn-block -submit btn-primary text-center m-auto"
                  >
                    <span>รับทราบ</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
  <footerSection></footerSection>
  
      <!-- Global site tag (gtag.js) - Google Analytics -->
  <!-- Facebook Pixel Code -->
  <!-- End Facebook Pixel Code -->
              </div>
  
  </div>
  </template>
  
  <script>
  import router from './../router'
  import firebase from 'firebase/app'
  import moment from 'moment-timezone'
  export default {
    name: 'Home',
    components: {
      headerSection: () => import('./Header.vue'),
      footerSection: () => import('./Footer.vue')
    },
    mounted () {
      // Bonn.boots.push(function() {
      //   setTimeout(function() {
      //     $('#bankInfoModal').modal('show');
      //   }, 500);
      // });
      this.$store.dispatch('user/getBanners').catch(err => { console.error(err) })
      this.checkAnoucement()
      this.$store.dispatch('user/getSlips').catch(err => { console.error(err) })
    },
    computed: {
      user () {
        return this.$store.state.user.user
      },
      banners () {
        return this.$store.state.user.banners
      },
      swiper () {
        return this.$refs.mySwiper.$swiper
      },
      lastbonus () {
        return this.$store.state.user.lastbonus
      },
      hotgames () {
        return this.$store.state.user.hotGames
      },
      splips () {
        return this.$store.state.user.splips
      },
      newgames () {
        return this.$store.state.user.newGames
      },
      casinos () {
        return this.$store.state.user.casinoGames
      },
      slotbrands () {
        return this.$store.state.user.slotbrands
      }
    },
    data () {
      return {
        continueCheck: false,
        partner: false,
        cashback: false,
        roulet: false,
        spin: false,
        receiveAnounceMent: false,
        anoucement: {},
        balance: 0,
        fa_spin: false,
        swiperOptions: {
          loop: true,
          speed: 2000,
          autoplay: {
            delay: 2000,
            disableOnInteraction: false
          },
          breakpoints: {
            1024: {
              slidesPerView: 3,
              spaceBetween: 40
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 20
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10
            }
          },
          centeredSlides: true,
          spaceBetween: 30,
          grabCursor: true,
          pagination: {
            el: '.swiper-pagination'
          }
          // Some Swiper option/callback...
        }
      }
    },
    methods: {
      getUserId () {
        const user = localStorage.getItem('userData')
        if (user) {
          const _user = JSON.parse(user)
          return _user
        } else {
          firebase.auth().signOut()
          localStorage.removeItem('userData')
        }
      },
      nl2br (str, is_xhtml) {
        if (typeof str === 'undefined' || str === null) {
          return ''
        }
        var breakTag =
          is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>'
        return (str + '').replace(
          /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
          '$1' + breakTag + '$2'
        )
      },
      // async moveCreditToWallet () {
      //   this.$store.commit('user/SET_LOADING', true)
      //   const user = this.getUserId()
      //   this.$store.dispatch('user/moveCreditToWallet').catch(err => { console.error(err) })
      //   this.$store.dispatch('user/getLastBonus', user).finally(res => {
      //     this.$store.commit('user/SET_LOADING', false)
      //     if (this.lastbonus.turncredit) {
      //       this.$store.commit('user/SET_ALERT_WITHDRAWALL', true)
      //     }
      //   }).catch(err => { console.error(err) })
      // },
      async checkPromotions () {
        this.$store.commit('user/SET_LOADING', true)
        this.$store.dispatch('user/checkPromotions')
          .finally((res) => {
            this.$store.commit('user/SET_LOADING', false)
          })
          .catch((err) => {
            console.error(err)
          })
      },
      async checkAnoucement () {
          const user = this.getUserId()
          if(user){
              const anoucement = (
                  await firebase
                  .database()
                  .ref('/anoucement/1')
                  .once('value')
              ).val()
              if (anoucement && anoucement.status) {
                  if (!anoucement.users || !anoucement.users[user.key]) {
                  this.anoucement = anoucement
                  $('#ads-no-show-again').modal('show')
                  }
              }
          }
      },
      submitAnoucement () {
        if (this.receiveAnounceMent) {
          const user = this.getUserId()
          firebase
            .database()
            .ref('/anoucement/1/users')
            .update({
              [user.key]: true
            })
        }
      },
      getGamesLength (games) {
        let len = 0
        for (const x in games) {
          len += games[x].games.length
        }
        return len
      }
    }
  }
  </script>
  <style scoped>
  @media screen and (max-width: 575.98px) {
      .mobile-video {
          width: 100%;
      }
  }
  </style>
  